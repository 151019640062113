import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "git-secret"
    }}>{`Git secret`}</h1>
    <p>{`Since we want all code in the same place, this also applies to environment files, charts or other sensitive information. Because it's a bad practice to have this information exposed in your git repository, we use git secret to encrypt/decrypt these files. `}</p>
    <h2 {...{
      "id": "prerequisites"
    }}>{`Prerequisites`}</h2>
    <ol>
      <li parentName="ol">{`Install GPG if it's not available on your PC. Mac: Install `}<a parentName="li" {...{
          "href": "https://brew.sh/"
        }}>{`Brew`}</a>{` then `}<inlineCode parentName="li">{`brew install gnupg`}</inlineCode>{`, Windows: Install `}<a parentName="li" {...{
          "href": "https://gpg4win.org/download.html"
        }}>{`GPG4WIN`}</a>{`, Linux (if not already installed) `}<inlineCode parentName="li">{`sudo apt-get install gpg`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Generate a GPG key with `}<inlineCode parentName="li">{`gpg --full-generate-key`}</inlineCode>{` and then select `}<inlineCode parentName="li">{`RSA and RSA (default)`}</inlineCode>{`. Use `}<inlineCode parentName="li">{`4096`}</inlineCode>{` as bit length. Key does not have to expire. Enter your name and email address (`}<inlineCode parentName="li">{`....@hybrit.org`}</inlineCode>{`).`}</li>
      <li parentName="ol">{`Export your public key with `}<inlineCode parentName="li">{`gpg --armor --export ....@hybrit.org > public-key.gpg`}</inlineCode>{` and save it somewhere.`}</li>
    </ol>
    <h2 {...{
      "id": "installing-git-secret"
    }}>{`Installing git secret`}</h2>
    <p>{`Follow `}<a parentName="p" {...{
        "href": "https://git-secret.io/installation"
      }}>{`the installation guide`}</a>{` at git-secret.io. For Mac and Unix, this should be straight forward.`}</p>
    <p>{`For `}<strong parentName="p">{`Windows`}</strong>{`, a number of solutions are provided. A `}<a parentName="p" {...{
        "href": "https://docs.microsoft.com/en-us/windows/wsl/"
      }}>{`Windows Subsystem for Linux`}</a>{` (WSL) is an OK solution, allowing you to install a Unix distribution like Ubuntu. You can then open a `}<strong parentName="p">{`wsl`}</strong>{` terminal in Visual Studio Code and run `}<inlineCode parentName="p">{`git secret`}</inlineCode>{` commands from there. If you use a separate terminal, use the `}<inlineCode parentName="p">{`wsl`}</inlineCode>{` command.`}</p>
    <blockquote>
      <p parentName="blockquote">{`If using WSL, it may happen that your permission will be denied when you try to run '`}<inlineCode parentName="p">{`PREFIX="/usr/local" make install`}</inlineCode>{`'. To solve this, add `}<inlineCode parentName="p">{`sudo`}</inlineCode>{` at the beginning of the command and enter the password of your Linux user to run the command as a super user. `}<a parentName="p" {...{
          "href": "https://linuxpip.org/fix-chown-operation-not-permitted#No_root_privileges"
        }}>{`Read more here`}</a>{` on how Linux 'ownership' works.`}</p>
    </blockquote>
    <p>{`The other working solution is using `}<a parentName="p" {...{
        "href": "https://www.msys2.org/"
      }}>{`MSYS`}</a>{`. This also installs MINGW64 (`}<em parentName="p">{`MSYS is required for that solution anyway`}</em>{`):`}</p>
    <ol>
      <li parentName="ol">{`Install the package from `}<a parentName="li" {...{
          "href": "https://www.msys2.org/"
        }}>{`https://www.msys2.org/`}</a></li>
      <li parentName="ol">{`Go to `}<inlineCode parentName="li">{`C:\\msys64`}</inlineCode>{` on your drive and open `}<inlineCode parentName="li">{`mingw64.exe`}</inlineCode>{`. (`}<em parentName="li">{`if indexed, Windows Search will list it as MSYS2 MINGW64`}</em>{`)`}</li>
      <li parentName="ol">{`In this terminal, run `}<inlineCode parentName="li">{`pacman -S [packages]`}</inlineCode>{`, where `}<inlineCode parentName="li">{`[packages]`}</inlineCode>{` is the list of required packages:`}<pre parentName="li"><code parentName="pre" {...{
            "className": "language-sh"
          }}>{`pacman -S gnupg make man git gawk file
`}</code></pre></li>
    </ol>
    <p>{`Using MSYS has one downside: you must use a MSYS terminal to run the command, which requires navigating to your project folder manually. It is possible to `}<a parentName="p" {...{
        "href": "https://stackoverflow.com/questions/43302853/using-msys-shell-in-visual-studio-code"
      }}>{`add MSYS to Visual Studio Code`}</a>{` as an available terminal, though.`}</p>
    <h2 {...{
      "id": "using-git-secret"
    }}>{`Using git secret`}</h2>
    <ol>
      <li parentName="ol">{`Inside a git repository, open a console and execute `}<inlineCode parentName="li">{`git secret init`}</inlineCode></li>
      <li parentName="ol">{`Add secret files with `}<inlineCode parentName="li">{`git secret add [filenames]`}</inlineCode></li>
      <li parentName="ol">{`Before committing, run `}<inlineCode parentName="li">{`git secret hide`}</inlineCode></li>
      <li parentName="ol">{`Decrypt files with `}<inlineCode parentName="li">{`git secret reveal`}</inlineCode></li>
    </ol>
    <h2 {...{
      "id": "adding-someone"
    }}>{`Adding someone`}</h2>
    <ol>
      <li parentName="ol">{`Inside a git respoitory, open a console.`}</li>
      <li parentName="ol">{`Receive someones (public) GPG key and import it with `}<inlineCode parentName="li">{`gpg --import [public-key-file]`}</inlineCode></li>
      <li parentName="ol">{`OPTIONAL: It might happen you need to trust the key (locally), then run `}<inlineCode parentName="li">{`gpg --lsign-key USER-ID`}</inlineCode>{` (you can run `}<inlineCode parentName="li">{`gpg --list-keys`}</inlineCode>{` to show all keys and their user ids)`}</li>
      <li parentName="ol">{`Add yourself to the repository with `}<inlineCode parentName="li">{`git secret tell ....@hybrit.org`}</inlineCode></li>
    </ol>
    <h2 {...{
      "id": "show-added-people"
    }}>{`Show added people`}</h2>
    <p>{`To view the users who are added to git secret, you can run the command `}<inlineCode parentName="p">{`git secret whoknows`}</inlineCode>{`. This will give you a list of email addresses to which the key is linked.`}</p>
    <h2 {...{
      "id": "adding-cicd"
    }}>{`Adding CI/CD`}</h2>
    <p>{`Since our CI/CD tooling needs access to encrypted files (e.g. to deploy secrets or set environment variables etc), someone also needs to add the
`}<inlineCode parentName="p">{`cicd@hybrit.org`}</inlineCode>{` key to git secret. This key is available in lastpass. You can then run the same commands as described in Adding someone.`}</p>
    <h2 {...{
      "id": "troubleshooting"
    }}>{`Troubleshooting`}</h2>
    <h3 {...{
      "id": "when-i-run-git-secret-reveal-my-decryption-fails-no-secret-key-error"
    }}>{`When I run `}<inlineCode parentName="h3">{`git secret reveal`}</inlineCode>{`, my decryption fails: no secret key error`}</h3>
    <p>{`Run the following commands and check if your keys are shown. `}<inlineCode parentName="p">{`[key]`}</inlineCode>{` is optional and can be used to filter by key (your key is most likely called '`}<em parentName="p">{`...@hybrit.org`}</em>{`')`}</p>
    <pre><code parentName="pre" {...{}}>{`gpg --list-keys [key]
gpg --list-secret-keys [key]
`}</code></pre>
    <p>{`If no keys are shown (and assuming you used `}<inlineCode parentName="p">{`gpg`}</inlineCode>{` to generate one), you'll need to import your private GPG key to the machine you're running the `}<inlineCode parentName="p">{`git secret`}</inlineCode>{` command on. If you're running multiple environments (for example, Windows and Linux), you can extract the key from one environment and import it into the other.`}</p>
    <p>{`The following guide will help you with extracting and importing:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://makandracards.com/makandra-orga/37763-gpg-extract-private-key-and-import-on-different-machine"
        }}>{`GPG: Extract private key and import on different machine`}</a></li>
    </ul>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note`}</strong>{`: if you're importing and `}<inlineCode parentName="p">{`private.key`}</inlineCode>{` is not found, your terminal is likely not running commands in the same folder as the key. Navigate there, and try again.`}</p>
    </blockquote>
    <p>{`If the above fails, the following guide has a few other solutions you may want to try, such as restarting the `}<inlineCode parentName="p">{`gpg-agent`}</inlineCode>{`:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://linuxhint.com/solve-gpg-decryption-failed-no-secret-key-error/"
        }}>{`How to solve gpg: decryption failed: No secret key error`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      